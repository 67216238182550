import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LazyLoad from "react-lazyload"
import ShapePartner from "../components/shapes/ShapePartner"

const ProgramPartnerski = () => (
  <Layout>
    <SEO title="Program Partnerski" />

    <main className="partner_hidden_page">
      <ShapePartner />

      <section className="page_header_wrapper">
        <h1 className="page_header smaller">
          &#60;Partnerstwo w ramach pozyskiwania <br /> klientów ForSecure/&#62;
        </h1>
      </section>

      <section className="container-fluid partner_hidden_section_who">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <LazyLoad once>
              <img
                className="img-fluid"
                src={require("../assets/images/partner_section_who_image.svg")}
                alt=""
              />
            </LazyLoad>
          </div>
          <div className="col-lg-6">
            <h3>Kim jesteśmy?</h3>
            <div className="row custom_row">
              <div className="col-sm-6">
                <p>
                  Wysoce wykwalifikowani specjaliści od web designerów, UX/UI,
                  po front-end, back-end oraz management
                </p>
              </div>
              <div className="col-sm-6">
                <p>
                  Posiadamy wszechstronną wiedzę na temat zarządzania stronami
                  internetowymi
                </p>
              </div>
              <div className="col-sm-6">
                <p>
                  Zabezpieczamy, aktualizujemy i na bieżąco serwisujemy strony
                  oraz sklepy internetowe
                </p>
              </div>
              <div className="col-sm-6">
                <p>
                  Ponad 50 stałych i zadowolonych klientów korzystających z
                  naszej usługi
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid partner_hidden_section_how">
        <h3>Jak działamy?</h3>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <LazyLoad once>
              <img
                className="img-fluid"
                src={require("../assets/images/home_ico_service_1.svg")}
                alt=""
              />
            </LazyLoad>
            <p>
              Przeprowadzamy audyt strony/sklepu www Klienta w celu jej szybkiej
              analizy
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <LazyLoad once>
              <img
                className="img-fluid"
                src={require("../assets/images/home_ico_service_2.svg")}
                alt=""
              />
            </LazyLoad>
            <p>
              Przedstawiamy nasze rekomendacje odnośnie niezbędnych zmian i
              potrzebnych aktualizacji
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <LazyLoad once>
              <img
                className="img-fluid"
                src={require("../assets/images/home_ico_service_3.svg")}
                alt=""
              />
            </LazyLoad>
            <p>
              Zabezpieczamy biznes naszego Klienta w sieci, jak i dane jego
              Klientów, tak aby mógł on w pełni skupić się tylko i wyłącznie na
              prowadzeniu swojego biznesu
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <LazyLoad once>
              <img
                className="img-fluid"
                src={require("../assets/images/home_ico_service_4.svg")}
                alt=""
              />
            </LazyLoad>
            <p>
              Na bieżąco serwisujemy, monitorujemy i aktualizujemy stronę
              naszego Klienta w ramach przysługujących mu miesięcznie godzin
              abonamentowych w zależności od wybranego pakietu
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid partner_hidden_section_positioning">
        <div className="row">
          <div className="col-lg-5">
            <h3>Pozyskanie klientów - rola naszego partnera w biznesie</h3>
            <ul>
              <li>
                Krótko objaśnia potencjalnym klientom z czego wynika potrzeba
                serwisowania, aktualizowania, czy tez zabezpieczania na bieżąco
                stron internetowych.
              </li>
              <li>
                Udziela potencjalnym klientom informacji o firmie ADream jako
                godnego i rzetelnego partnera w biznesie.
              </li>
              <li>
                Uzyskuje od potencjalnego klienta zgodę na kontakt ze strony
                naszego przedstawiciela lub sam kieruje go bezpośredvnio do
                skontaktowania się z nami.
              </li>
              <li>
                <LazyLoad once>
                  <img
                    className="img_lines"
                    src={require("../assets/images/partner_section_positioning_lines.svg")}
                    alt=""
                  />
                </LazyLoad>
              </li>
            </ul>
          </div>
          <div className="col-lg-5 offset-lg-2 partner_hidden_section_positioning__side-image">
            <LazyLoad once>
              <img
                className="img-fluid"
                src={require("../assets/images/partner_section_positioning_image.svg")}
                alt=""
              />
            </LazyLoad>
          </div>
        </div>
        <p className="desc">
          Nasz partner nie jest przedstawicielem firmy, nie spisuje umowy z
          Klientem, nie zbiera dokumentacji potrzebnej do wdrożenia usługi i nie
          ciąży nad nim za nią żadna odpowiedzialność.
        </p>
      </section>

      <section className="container-fluid partner_hidden_section_table">
        <div className="partner_hidden_section_table__holder">
          <div className="partner_hidden_section_table__table">
            <div className="row table__content">
              <div className="col-2">
                <h4>Podmiot</h4>
              </div>
              <div className="col-5">
                <h4>Obowiązki</h4>
              </div>
              <div className="col-5">
                <h4>Korzyści</h4>
              </div>
            </div>
            <br />
            <div className="row align-items-center row_entity table__content">
              <div className="col-2">
                <h4>Partner</h4>
              </div>
              <div className="col-10">
                <div className="row">
                  <div className="col-6 column purple">
                    Pozyskanie kontaktu do klienta
                  </div>
                  <div className="col-6 column purple">
                    Prowizja wypłacana każdego miesiąca przez cały okres trwania
                    abonamentu przekazanego klienta
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 column"></div>
                  <div className="col-6 column">
                    Brak odpowiedzialności i jakichkolwiek obowiązków związanych
                    z samą obsługą przekazanego nam klienta
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 column purple"></div>
                  <div className="col-6 column purple">
                    Ludzie (znajomi, partnerzy w biznesie) zadowoleni z
                    rzetelnej rekomendacji
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 column"></div>
                  <div className="col-6 column">
                    Raz w roku możliwość bycia wylosowanym spośród wszystkich
                    naszych partnerów do nagrody Premium w postaci specjalnej
                    niespodzianki
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center row_entity table__content">
              <div className="col-2">
                <h4>ForSecure</h4>
              </div>
              <div className="col-10">
                <div className="row">
                  <div className="col-6 column purple">
                    Pozyskanie i utrzymanie klienta
                  </div>
                  <div className="col-6 column purple">
                    Zysk z każdego abonamentu (subskrypcji) - miesięcznych lub
                    rocznych
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 column">
                    Obsługa klienta przez cały okres trwania usługi
                    abonamentowej
                  </div>
                  <div className="col-6 column"></div>
                </div>
                <div className="row">
                  <div className="col-6 column purple">
                    Pełna odpowiedzialność za stronę/sklep Klienta i jej
                    funkcjonowanie oraz jakość naszej usługi
                  </div>
                  <div className="col-6 column purple"></div>
                </div>
                <div className="row">
                  <div className="col-6 column">
                    Sporządzanie miesięcznych raportów z wykonanych prac
                  </div>
                  <div className="col-6 column"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid partner_hidden_section_simulation">
        <h3>
          Symulacja zysków <br /> dla naszych partnerów
        </h3>
        <div className="row">
          <div className="col-lg-6">
            <ul>
              <li>
                Comiesięczna prowizja bazowa to 20% za każdego pozyskanego
                Klienta, wypłacana przez cały okres trwania zakupionego przez
                niego abonamentu
              </li>
              <li>
                Prowizja dla naszego partnera wzrasta wraz z osiągniętym progiem
                pozyskanych klientów tj. do 10 klientów 20%, między 10-30
                klientów prowizja wynosi 25%, od 31 pozyskanych klientów
                prowizja wynosi 30%.
              </li>
              <li>Wypłata prowizji następuje co miesiąc.</li>
              <li>
                Prowizja za 1 pozyskanego klienta (przy założeniu najmniejszego
                możliwego abonamentu tj. 399 zł/mc) wynosi 60 zł.
              </li>
            </ul>
          </div>
          <div className="col-lg-5 offset-lg-1 graph-holder">
            <p className="axle axle__y">Liczba pozyskanych klientów</p>
            <p className="axle axle__x">
              Prowizja{" "}
              <span>Na przykładzie abonamentu minimalnego 299zł/mc</span>
            </p>
            <LazyLoad once>
              <img
                className="img-fluid"
                src={require("../assets/images/partner_section_simulation_chart.svg")}
                alt=""
              />
            </LazyLoad>
          </div>
        </div>
      </section>

      <section className="container-fluid partner_hidden_section_payment">
        <h3>Jak się rozliczamy?</h3>
        <div className="row align-items-center">
          <div className="col-md-6 partner_hidden_section_payment__item">
            <div className="row align-items-center">
              <div className="col-3">
                <LazyLoad once>
                  <img
                    className="img-fluid"
                    src={require("../assets/images/partner_ico_payment_1.svg")}
                    alt=""
                  />
                </LazyLoad>
              </div>
              <div className="col-9">
                <p>
                  Nasz partner otrzymuje 30% co miesiąc od każdego pozyskanego
                  klienta, który podpisał umowę abonamentową.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 partner_hidden_section_payment__item">
            <div className="row align-items-center">
              <div className="col-3">
                <LazyLoad once>
                  <img
                    className="img-fluid"
                    src={require("../assets/images/partner_ico_payment_2.svg")}
                    alt=""
                  />
                </LazyLoad>
              </div>
              <div className="col-9">
                <p>
                  Nasz partner otrzymuje także dostęp do systemu informatycznego
                  naszej firmy w zakresie statusu wypłaconych prowizji, czy też
                  listy aktywnych subskrypcji z przekazanych nam klientów.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid">
        <div className="page_navigation description">
          <div className="row no-gutters">
            <div className="col-12">
              <div className="inner">
                <h3>Kto może zostać naszym klientem?</h3>
                <p>
                  Każda osoba posiadająca stronę lub sklep internetowy,
                  szczególnie tych, które działają w oparciu o system CMS
                  WordPress.
                </p>
                <Link to="/kontakt" className="btn_custom">
                  &#60;Kontakt/&#62;
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default ProgramPartnerski
